
import React, { useEffect, useState } from 'react'
import api from '../../services/ApiService';
import {Form, FormText, Image } from 'react-bootstrap';
import {Modal, ModalContent, ModalHeader, ModalBody, Button, Input, Link, Checkbox} from "@nextui-org/react";
import './ButtonLogin.styles.css';
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import iconGoogle from '../../assets/icons/icons-google.png';
import AuthUtils from '../../utils/AuthUtils';
import {EyeFilledIcon} from "./EyeFilledIcon";
import {EyeSlashFilledIcon} from "./EyeSlashFilledIcon";
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/UserContext';

const Login = ({ handleChangeForm, handleClose }) => {
  const { authCorrect } = useUser();
  const [ error, setError] = useState(null);
  const [ errorMessage, setErrorMessage] = useState(null);
  const [ userGoogle, setUserGoogle ] = useState([]);
  const [ errors, setErrors ] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const { t } = useTranslation();

  useEffect(() => {
    setError(null);
    setErrorMessage(null);
  }, []);

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUserGoogle(codeResponse)
    },
    onError: (error) => console.log('Login Failed:', error)
  });
  
  useEffect(() => {
    if (userGoogle && userGoogle.access_token != undefined) {
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userGoogle.access_token}`, {
          headers: {
            Authorization: `Bearer ${userGoogle.access_token}`,
            Accept: 'application/json'
          }})
          .then(async (res) => {
            try {
              const response = await api.users.authGoogle(res.data);
              
              handleClose();
              
              if (response.status == 201 || response.status == 200){
                const {token,user} = response.data;
                authCorrect(user,token);
              }

              if (response.status == 400) {
                const newErrors = {};
                newErrors.register = error.response.data.error;
                setErrors(newErrors);
              }
            } catch (error) {
              if (error.response && error.response.data) {
                setError(true);
                setErrorMessage(error.response.data.error);
              }  
            }
            
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              setError(true);
              setErrorMessage(error.response.data.error);
            }
          });
    }
  },[ userGoogle ]);


  const handleLoginFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    try {
      if (validateForm(formData)){ 
        const response = await api.users.login({
          email: formData.get('email'),
          password: formData.get('password'),
        });
        
        handleClose();
        
        if (response.status == 200){
          const {token,user} = response.data;
          authCorrect(user,token);
        }
    }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      if (error.response && error.response.data) {
        setError(true);
        setErrorMessage(error.response.data?.error);
      }
    }
  };

  const validateForm = (formData) => {
    let valid = true;
    const newErrors = {};
    
    if (formData.get('password').length < 8) {
      newErrors.password = t('auth.passwordValidacion');
      valid = false;
    }
  
    setErrors(newErrors);
    return valid;
  };

return (
    <Modal
      isOpen={true}
      onOpenChange={handleClose}
      placement='top-center'
    >
    <ModalContent>
      {(onClose) => (
        <>
          <ModalHeader className='flex flex-col gap-3'>{t('auth.login')}</ModalHeader>
          <ModalBody>
            <Button radius="full" color="primary" variant="ghost" size='lg'  className="form-btn" onClick={loginGoogle}>
              <Image src={iconGoogle} className="google-icon" roundedCircle />{' '}
              {t('auth.loginGoogle')}
            </Button>
            
            <hr/>

            <Form onSubmit={handleLoginFormSubmit} className='form'>
              <Input 
                radius="full" 
                variant="bordered" 
                label={t('auth.email')}
                placeholder={t('auth.emailPlaceholder')}
                type="email"
                name='email' 
                required 
                autoFocus />
              {errors.email && <FormText className="text-danger">{errors.email}</FormText>}

              <Input
                radius="full"
                variant="bordered"
                label={t('auth.password')}
                placeholder={t('auth.passwordPlaceholder')}
                name="password"
                endContent={
                  <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                required
              />
              {errors.password && <FormText className="text-danger">{errors.password}</FormText>}
              {error && errorMessage && <FormText className="text-danger">{errorMessage}</FormText>}

              <div className="flex py-2 px-1 justify-between">
                  <Checkbox
                    classNames={{
                      label: "text-small",
                    }}
                  >
                    {t('auth.recuerdame')}
                  </Checkbox>
                  <Link color="primary" href="#" size="sm">
                    {t('auth.passwordPregunta')}
                  </Link>
              </div>
              <div className='buttons-container'>
                <Button radius="full" color="primary" size='lg' type='submit' className="form-btn">
                  {t('auth.login')}
                </Button>
                
                <p className='sig-up-label'>
                  {t('auth.registroPregunta')}
                  <Link color="primary" onClick={handleChangeForm}>
                    {t('auth.registrate')}
                  </Link>
                </p>
              </div>
            </Form>
          </ModalBody>
          
            

          
        </>
      )}
    </ModalContent>
    </Modal>
  )
}

export default Login