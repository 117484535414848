import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Política de Privacidad</h1>
            <p><strong>Fecha de vigencia:</strong> 11/08/2024 </p>

            <h2>1. Introducción</h2>
            <p>En <strong>Esmibar</strong>, estamos comprometidos a proteger y respetar tu privacidad. 
            Esta Política de Privacidad explica cómo recopilamos, utilizamos, y protegemos la información personal 
            que nos proporcionas cuando accedes a nuestro sitio web www.esmibar.com o utilizas nuestros servicios. 
            Al utilizar nuestros servicios, aceptas los términos de esta política.</p>

            <h2>2. Información que Recopilamos</h2>
            <p>Recopilamos y tratamos la siguiente información personal:
                - Información de Identificación Personal: como nombre, dirección de correo electrónico, y cualquier otra información que proporciones voluntariamente.
                - Datos de Uso: Información sobre cómo accedes y utilizas nuestro sitio web, como la dirección IP, tipo de navegador, páginas visitadas, y el tiempo dedicado a esas páginas.
                - Datos de Cookies: Utilizamos cookies y tecnologías similares para rastrear la actividad en nuestro servicio y almacenar cierta información.</p>

            <h2>3. Cómo Utilizamos tu Información</h2>
            <p>Utilizamos la información que recopilamos para diversos fines:</p>
            <ul>
                <li>
                    <strong>Para Proporcionar y Mantener el Servicio:</strong>
                    <p>Para ofrecerte un acceso continuo a nuestro sitio web y servicios.</p>
                </li>
                <li>
                    <strong>Para Mejorar el Servicio:</strong>
                    <p>Analizar cómo utilizas nuestro servicio para mejorar y optimizar la experiencia del usuario.</p>
                </li>
                <li>
                    <strong>Para Comunicarnos Contigo:</strong>
                    <p>Enviarte notificaciones importantes, actualizaciones del servicio, y promociones que puedan interesarte.</p>
                </li>
                <li>
                    <strong>Para Cumplir con Obligaciones Legales:</strong>
                    <p>Usar y compartir tu información según lo requiera la ley, como responder a solicitudes gubernamentales.</p>
                </li>
            </ul>


            <h2>4. Compartición de Información</h2>
            <p>No vendemos ni compartimos tu información personal con terceros, salvo en las siguientes circunstancias:</p>
            <ul>
                <li>
                    <strong>Proveedores de Servicios:</strong>
                    <p>Podemos compartir tu información con proveedores de servicios que nos ayudan a operar nuestro sitio web y servicios, como servicios de hosting, análisis, y marketing. Estos terceros están obligados a proteger tu información y solo la utilizarán en nuestro nombre.</p>
                </li>
                <li>
                    <strong>Cumplimiento de la Ley:</strong>
                    <p>Podemos divulgar tu información personal si así lo exige la ley o en respuesta a solicitudes válidas por parte de autoridades públicas.</p>
                </li>
            </ul>

            <h2>5. Tus Derechos</h2>
            <p>En función de la legislación aplicable, tienes los siguientes derechos con respecto a tu información personal:</p>
            <ul>
                <li>
                    <strong>Acceso y Rectificación:</strong>
                    <p>Puedes solicitar acceso a tu información personal y corregir cualquier dato inexacto.</p>
                </li>
                <li>
                    <strong>Eliminación:</strong>
                    <p>Puedes solicitar la eliminación de tu información personal, salvo que necesitemos conservarla para cumplir con obligaciones legales.</p>
                </li>
                <li>
                    <strong>Restricción del Tratamiento:</strong>
                    <p>Puedes solicitar que restrinjamos el tratamiento de tu información personal en determinadas circunstancias.</p>
                </li>
                <li>
                    <strong>Portabilidad de los Datos:</strong>
                    <p>Puedes solicitar una copia de tu información personal en un formato estructurado y de uso común.</p>
                </li>
            </ul>
            <p>Para ejercer cualquiera de estos derechos, por favor contáctanos en esmibar.adm@gmail.com.</p>

            <h2>6. Seguridad de la Información</h2>
            <p>Implementamos medidas de seguridad técnicas y organizativas adecuadas para proteger tu información personal contra el acceso no autorizado, la alteración, divulgación o destrucción. Sin embargo, ninguna transmisión de datos a través de Internet o sistema de almacenamiento electrónico es completamente segura, y no podemos garantizar la seguridad absoluta de tu información.</p>

            <h2>7. Cookies</h2>
            <p>Nuestro sitio web utiliza cookies para mejorar tu experiencia de usuario. Las cookies son archivos pequeños que se almacenan en tu dispositivo. Puedes configurar tu navegador para rechazar todas las cookies o para que te avise cuando se envíe una cookie. Sin embargo, si no aceptas las cookies, es posible que no puedas utilizar algunas partes de nuestro servicio.</p>

            <h2>8. Cambios a Esta Política</h2>
            <p>Podemos actualizar esta Política de Privacidad de vez en cuando para reflejar cambios en nuestras prácticas o por razones operativas, legales o regulatorias. Cualquier cambio será publicado en esta página con la fecha de actualización revisada. Te recomendamos que revises periódicamente esta política para estar al tanto de cómo protegemos tu información.</p>

            <h2>9. Contacto</h2>
            <p>Si tienes alguna pregunta, inquietud o solicitud sobre esta Política de Privacidad o sobre cómo manejamos tu información personal, no dudes en contactarnos</p>
            <p>Email: <a href="mailto:esmibar.adm@gmail.com">esmibar.adm@gmail.com</a></p>
        </div>
    );
};

export default PrivacyPolicy;
