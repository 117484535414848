import React, { useEffect, useState } from 'react';
import './BarList.styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { EffectCards, Navigation } from 'swiper/modules';
import StarRating from '../StarRating/StarRating';
import utils from '../../utils/MapUtils';
import TipoEtiqueta from '../TipoEtiqueta/TipoEtiqueta';
import { Card, Image } from '@nextui-org/react';
import { CardBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Clock, Euro, MapPin, Star, Tag } from 'lucide-react';
import BarImageService from '../../services/BarImageService';

const BarList = ({ barsData, onSelectBar  }) => {

  const [slidesPorVista, setSlidesPorVista] = useState(calcularSlidesPorVista());
  const [chipsPorVista, setChipsPorVista] = useState(calcularChipsPorVista());
  const { t } = useTranslation();
  const [barImagenPrincipal, setBarImagenPrincipal] = useState({});

    // Función para obtener la imagen del bar
    const obtenerImagenPrincipalByBarId = async (barId) => {
        const imageUrl = await BarImageService.getBarImagenPrincipal(barId);
        
        setBarImagenPrincipal((prev) => ({
            ...prev,
            [barId]: imageUrl || "https://images.unsplash.com/photo-1538488881038-e252a119ace7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
        }));
    };

    // Cargar imágenes de cada bar al cargar el componente o cambiar `barsData`
    useEffect(() => {
        barsData.forEach((bar) => {
            if (!barImagenPrincipal[bar._id]) {
              obtenerImagenPrincipalByBarId(bar._id);
            }
        });
    }, [barsData]);

  function calcularSlidesPorVista() {
    const anchoVentana = window.innerWidth;
    // Calcula el número de elementos según el ancho de la ventana
    // Puedes ajustar estos valores según tus necesidades
    if (anchoVentana >= 1200) {
      return 4;
    } else if (anchoVentana >= 992) {
      return 3;
    } else if (anchoVentana >= 768) {
      return 2;
    } else {
      return 1;
    }
  }

  function calcularChipsPorVista() {
    const anchoVentana = window.innerWidth;
    // Calcula el número de elementos según el ancho de la ventana
    // Puedes ajustar estos valores según tus necesidades
    if (anchoVentana >= 1200) {
      return 4;
    } else if (anchoVentana >= 768) {
      return 3;
    } else {
      return 2;
    }
  }

  useEffect(() => {
    function manejarCambioVentana() {
      setSlidesPorVista(calcularSlidesPorVista());
      setChipsPorVista(calcularChipsPorVista());
    }

    // Agregar oyente para cambios de tamaño de ventana
    window.addEventListener('resize', manejarCambioVentana);

    // Limpiar el oyente cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', manejarCambioVentana);
    };
  }, []);

  let startX = 0;
  let startY = 0;
  let isSwiping = false;

  // Detectar cuando comienza el toque o el clic
  const handleStart = (e) => {
    // Diferenciar entre eventos táctiles y de ratón
    const touch = e.touches ? e.touches[0] : e;
    
    // Guardar las coordenadas iniciales
    startX = touch.clientX;
    startY = touch.clientY;
    isSwiping = false; // Reiniciar el estado de swipe
  };

  // Detectar cuando se mueve el dedo o el ratón
  const handleMove = (e) => {
    const touch = e.touches ? e.touches[0] : e;

    // Comparar las coordenadas actuales con las iniciales
    const diffX = Math.abs(touch.clientX - startX);
    const diffY = Math.abs(touch.clientY - startY);

    // Si el movimiento en X o Y es mayor que un umbral, consideramos que es un swipe
    if (diffX > 5 || diffY > 5) {
      isSwiping = true;
    }
  };

  const handleEnd = (bar) => {
    if (!isSwiping) {
      onSelectBar(bar); // Solo seleccionar el bar si no se desliza
    }
  };
  

  return (
    <Swiper 
      slidesPerView={slidesPorVista}
      loop={true}
      className="h-full"
    >
      {barsData.map((bar) => (
        <SwiperSlide key={bar._id} className="d-flex justify-content-center align-items-center">
          <Card 
            key={bar._id}
            className='w-full h-[90%] max-w-[350px] p-1 mx-2'
            shadow='lg'
            isPressable 
            // Eventos para dispositivos táctiles
            onTouchStart={handleStart}
            onTouchMove={handleMove}
            onTouchEnd={() => handleEnd(bar)}
            // Eventos para ordenadores (ratón)
            onMouseDown={handleStart}
            onMouseMove={handleMove}
            onMouseUp={() => handleEnd(bar)}
          >
            <CardBody className='flex flex-col p-2'>
              <div className="grid grid-cols-12 gap-2 items-start">
                <div className="col-span-4">
                  <Image
                    className="object-cover w-full aspect-square rounded-lg"
                    src={barImagenPrincipal[bar._id]}
                    alt={bar.name}
                  />
                </div>

                
                <div className="flex flex-col col-span-8 gap-1">
                  <h5 className="font-semibold text-foreground/90 text-sm line-clamp-2 mb-0" title={bar.name}>{bar.name}</h5>
                  <div className="flex items-center text-small text-foreground/80">
                    <Star className="w-4 h-4 mr-1 text-yellow-400" />
                    <span>{utils.formato1Decimal(bar.rating)}</span>
                    <span className="ml-1 text-xs">({bar.numRatings} valoraciones)</span>
                  </div>

                  <div className="flex items-start">
                    <Euro className="w-4 h-4 mr-1" />
                    <span className="text-xs text-foreground/70">
                      {bar.priceLevel ? 'Precio: ' + '€'.repeat(bar.priceLevel) : 'Precio no disponible'}
                    </span>
                  </div>

                  <div className="flex items-start">
                    <MapPin className="w-4 h-4 mr-1 mt-1 flex-shrink-0" />
                    <span className="text-xs text-foreground/70 line-clamp-2">
                      {utils.getAddres(bar.address)}
                    </span>
                  </div>

                  {/* <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-1" />
                    <span className="text-xs text-foreground/70">
                      {bar.openingHours?.open_now ? 'Abierto' : 'Cerrado'}
                    </span>
                  </div> */}
                </div>
              </div>

              <div className="mt-1 flex flex-wrap justify-center gap-1">
                {bar.types.slice(0, chipsPorVista).map((type, index) => (
                  <TipoEtiqueta key={index} tipo={type} />
                ))}
              </div>

            </CardBody>
          </Card>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BarList;