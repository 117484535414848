import React from 'react';

const CookiePolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Política de Cookies de Esmibar</h1>
      <p><strong>Última actualización:</strong> 11/08/2024</p>

      <h2>1. Introducción</h2>
      <p>En Esmibar, utilizamos cookies y tecnologías similares para mejorar la experiencia de nuestros usuarios, realizar análisis de uso, personalizar contenido y ofrecer publicidad dirigida. Esta Política de Cookies explica qué son las cookies, cómo las utilizamos y cómo puedes gestionarlas.</p>

      <h2>2. ¿Qué son las Cookies?</h2>
      <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro sitio web. Estas cookies nos permiten recordar tus preferencias, entender cómo interactúas con nuestro sitio, y ofrecerte una experiencia más personalizada y eficiente.</p>

      <h2>3. Tipos de Cookies que Utilizamos</h2>
      <p>En Esmibar, utilizamos los siguientes tipos de cookies:</p>
      <ul>
        <li><strong>Cookies de Sesión:</strong> Estas cookies son temporales y se eliminan automáticamente cuando cierras tu navegador. Nos permiten mantener tu sesión activa y recordar información clave mientras navegas por nuestro sitio.</li>
        <li><strong>Cookies de Terceros:</strong> Utilizamos cookies de terceros, como las de Google Analytics y herramientas de publicidad, para realizar análisis de uso y ofrecer anuncios más relevantes para ti. Estas cookies pueden persistir en tu dispositivo por un tiempo específico, generalmente 30 días.</li>
      </ul>

      <h2>4. ¿Por Qué Utilizamos Cookies?</h2>
      <p>Utilizamos cookies para los siguientes propósitos:</p>
      <ul>
        <li><strong>Mejorar la Experiencia del Usuario:</strong> Las cookies nos permiten recordar tus preferencias y mejorar la funcionalidad de nuestro sitio, haciendo tu experiencia más fluida y personalizada.</li>
        <li><strong>Realizar Análisis de Uso:</strong> A través de herramientas como Google Analytics, recopilamos datos sobre cómo interactúas con nuestro sitio, lo que nos ayuda a entender mejor tu comportamiento y mejorar continuamente nuestros servicios.</li>
        <li><strong>Personalizar Contenido:</strong> Utilizamos cookies para ofrecerte contenido que se ajuste a tus intereses y preferencias, mejorando así la relevancia de la información que te mostramos.</li>
        <li><strong>Publicidad Dirigida:</strong> Las cookies nos permiten ofrecerte anuncios que sean más relevantes para ti, basados en tus intereses y comportamientos anteriores en nuestro sitio.</li>
      </ul>

      <h2>5. Obtención del Consentimiento</h2>
      <p>Cuando visitas Esmibar por primera vez, te presentamos un banner de cookies que te informa sobre el uso de cookies en nuestro sitio. Al continuar navegando en nuestro sitio o hacer clic en "Aceptar", das tu consentimiento para el uso de cookies según esta política.</p>

      <h2>6. Cómo Gestionar Tus Preferencias de Cookies</h2>
      <p>Tienes la opción de aceptar o rechazar el uso de cookies a través de la configuración de tu navegador. La mayoría de los navegadores te permiten gestionar tus preferencias de cookies, incluyendo la posibilidad de bloquear o eliminar cookies. Sin embargo, desactivar cookies puede afectar la funcionalidad de nuestro sitio y limitar tu experiencia de usuario.</p>

      <h2>7. Cambios en Esta Política de Cookies</h2>
      <p>Podemos actualizar esta Política de Cookies ocasionalmente para reflejar cambios en nuestras prácticas y servicios. Cualquier cambio será publicado en esta página con la fecha de la última actualización. Te recomendamos que revises esta política periódicamente para mantenerte informado.</p>

      <h2>8. Contacto</h2>
      <p>Si tienes alguna pregunta o inquietud sobre nuestra Política de Cookies, no dudes en contactarnos en <a href="mailto:esmibar.adm@gmail.com">esmibar.adm@gmail.com</a>.</p>
    </div>
  );
};

export default CookiePolicy;
