import React, { createContext, useContext, useEffect, useState } from 'react';
import AuthUtils from '../utils/AuthUtils';
import { useTranslation } from 'react-i18next';
import apiUserRatings from '../services/ApiUserRatingService';
import { useNavigate } from "react-router-dom";

const UserContext = createContext(null);
const API_URL = process.env.REACT_APP_BACKEND_URL;

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [config, setConfig] = useState({});
    const [tastesAndPreferences, setTastesAndPreferences] = useState({});
    const [authenticated, setAuthenticated] = useState(AuthUtils.isAuth());
    const [profileImage, setProfileImage] = useState(null);
    const [ratings, setRatings] = useState({});
    const [selectedBar, setSelectedBarAqui] = useState(null);
    const [filtros, setFiltros] = useState([]);
    const [aplicarTodosFiltros, setAplicarTodosFiltros] = useState(false);
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
    const currentUser = AuthUtils.getUser();
    if (currentUser) {
        setUser(currentUser);
        setConfig(currentUser.configuracion);
        setTastesAndPreferences(currentUser.tastesAndPreferences);
        setProfileImage(obtenerImagenPerfil(currentUser));
        const idiomaPredeterminado = currentUser.configuracion.idioma || i18n.language;
        i18n.changeLanguage(idiomaPredeterminado);
        apiUserRatings.getRatingsByUser(currentUser._id).then(ratingsData => {
            console.log("Ratings fetched:", ratingsData);
            setRatings(ratingsData);
        }).catch(error => {
            console.error("Error fetching ratings:", error);
        });
    }
    }, []);

    const logout = () => {
        AuthUtils.logoutAuth();
        setUser(null);
        setConfig({});
        setTastesAndPreferences({})
        setAuthenticated(false);
        navigate('/'); 
    };

    const authCorrect = (user,token) => {
        AuthUtils.setAuth(user,token);
        setAuthenticated(true);
        setUser(user);
        setConfig(user.configuracion);
        setTastesAndPreferences(user.tastesAndPreferences);
        setAuthenticated(true);
        const idiomaPredeterminado = user.configuracion.idioma || i18n.language;
        i18n.changeLanguage(idiomaPredeterminado);
    }

    const obtenerImagenPerfil = (currentUser) => {
    return currentUser?.profileImage 
                ? `${API_URL}/${currentUser?.profileImage.replace(/\\/g, '/')}` 
                : currentUser?.profile?.picture;
    }

    const guardarUser = (currentUser) => {
        console.log("Valor de currentUser en guardarUser:", currentUser);

        console.log(currentUser);
        if (!currentUser || typeof currentUser !== 'object') {
            console.error("Invalid user object:", currentUser);
            return;
        }
        
        AuthUtils.setUser(currentUser)
        setUser(currentUser);
    }
    
    const profile = () => {
        return user?.profile;
    }

    const recalcularRatings = async () => {
        const newRatings = await apiUserRatings.getRatingsByUser(user?._id);
        setRatings(newRatings);
    }

    const setSelectedBar = (bar) => {
        console.log("setSelecedBar", bar);
        sessionStorage.setItem('barSelected', JSON.stringify(bar));
        setSelectedBarAqui(bar);
    }

    const guardarTagsEnBar = (tags) => {
		try {
			tags?.forEach(tag => {
				const { key, value } = tag;
                // Verificar si la clave de iteración existe; si no, inicializarla
                if (!selectedBar.iteraciones[key]) {
                    selectedBar.iteraciones[key] = {};
                }
                // Verificar si el valor de iteración existe; si no, inicializarlo en 0
                if (!selectedBar.iteraciones[key][value]) {
                    selectedBar.iteraciones[key][value] = 0;
                }

                // Incrementar la iteración
			    selectedBar.iteraciones[key][value] += 1;

			});
			// Incrementar el número de calificaciones
		    selectedBar.numRatings += 1;

            // Actualizar el estado
			setSelectedBar({ ...selectedBar });

			return { message: "OK" };

		} catch (error) {
			console.error("Error en actualizarIteracionesEnBar:", error);
			return { error: "ERROR" };
		}	
    }

    return (
    <UserContext.Provider value={{ 
        user, 
        guardarUser, 
        config, 
        logout, 
        authCorrect, 
        authenticated, 
        setAuthenticated, 
        profileImage, 
        obtenerImagenPerfil, 
        profile, 
        ratings,
        recalcularRatings,
        selectedBar,
        setSelectedBar,
        guardarTagsEnBar,
        filtros,
        setFiltros,
        tastesAndPreferences,
        aplicarTodosFiltros, 
        setAplicarTodosFiltros
    }}>
        {children}
    </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
