import { initDB } from './db';

const DB_NAME = 'BarsDatabase';
const STORE_NAME = 'BarsStore';

export const saveBarsToCache = async (bars) => {
  const db = await initDB(DB_NAME, STORE_NAME);
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  for (const bar of bars) {
    await store.put(bar); // Inserta o actualiza
  }

  await tx.done;
};

export const getAllBarsFromCache = async () => {
  const db = await initDB(DB_NAME, STORE_NAME);
  return db.getAll(STORE_NAME); // Devuelve todos los bares
};

export const deleteBarToCache = async (id) => {
  const db = await initDB(DB_NAME, STORE_NAME);
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  await store.delete(id); // Borra un bar por ID
  await tx.done;
};
