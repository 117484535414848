import React from 'react';
import { Listbox, ListboxItem } from '@nextui-org/react';
import './SearchResults.styles.css';

const SearchResults = ({ items, handleBarSelect }) => {

	// Función para manejar la selección de un elemento
	const handleSelection = (key) => {
		// Buscar el objeto correspondiente a la clave seleccionada
		const selectedItem = items.find(item => item._id === key);
		
		// Llamar a la función de manejo con el objeto seleccionado
		handleBarSelect(selectedItem);
	  };

	return (
		<div className="searchResultsContainer">
		<Listbox
			items={items}
			aria-label="Dynamic Actions"
			onAction={handleSelection}
			className="listbox"
		>
			{(item) => (
			
			<ListboxItem
				key={item._id}
				color="default"
				variant="faded"
				className="listItem"
			>
				{item.name}
			</ListboxItem>
			
			)}
		</Listbox>
		</div>
	);
};

export default SearchResults;
