import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Chip, Modal, ModalContent } from '@nextui-org/react';
import { ChevronLeft, ChevronRight, Share } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';
import BarImageService from '../../services/BarImageService';
import BarDetallesTabs from './BarDetallesTabs';
import BarStatusChip from './BarStatusChip';
import NotificationsPopUp from '../NotificationsPopUp/NotificationsPopUp';
import './BarDetalles.styles.scss';
import { filterIteraciones, filterRatingsUser, filterRatingsUserBar } from '../../utils/Utils';
import api from '../../services/ApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart, faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart, faBookmark as regularBookmark, faCommentDots, faPlusSquare, faXmark } from '@fortawesome/free-regular-svg-icons';
import SinSesion from '../SinSesionPopUp/SinSesion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const BarDetalles = ({ barId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { bar: initialBar } = location.state || {};
  const { ratings, selectedBar, setSelectedBar, user, authenticated, guardarUser } = useUser();
  const [isModalOpenNotifications, setModalOpenNotifications] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [shared, setShared] = useState(false);
  const [isOpenSinSesion, setIsOpenSinSesion] = useState(false);
  const navigate = useNavigate();
  const [barData, setBarData] = useState({
    iteracionesFiltradas: [],
    ratingsUser: [],
    ratingsUserBar: [],
    barImages: [],
    loadingImages: true,
    imageError: false,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0); // Para saber cuál imagen se hizo clic
  const [temaSeleccionado, setTemaSeleccionado] = useState(null);

  // Fetch bar details if not already selected
  useEffect(() => {
    const fetchBarDetails = async () => {
      
      if (!selectedBar || selectedBar._id !== barId) {
        try {
          const barDetails = await api.bars.getBarById(barId);
          setSelectedBar(barDetails);

        } catch (error) {
          console.error('Error fetching bar details:', error);
        }
      }
      
    };

    fetchBarDetails();
  }, [barId, selectedBar, setSelectedBar]);

  useEffect(() => {
    const savedBarSelected = sessionStorage.getItem('barSelected');
    if (!selectedBar && savedBarSelected) {
      setSelectedBar(JSON.parse(savedBarSelected));
    } else if (initialBar) {
      setSelectedBar(initialBar);
    }
  }, [initialBar, selectedBar, setSelectedBar]);

  useEffect(() => {
    const fetchBarImages = async () => {
      if (!selectedBar?._id) return;
  
      setBarData(prevState => ({ ...prevState, loadingImages: true, imageError: false }));
  
      try {
        const images = await BarImageService.getBarImages(selectedBar._id);
        const validImages = images.filter(img => img !== undefined && img !== null);
  
        setBarData(prevState => ({
          ...prevState,
          barImages: validImages.length > 0 ? [validImages[0], ...validImages] : [],
          loadingImages: false,
        }));
      } catch (error) {
        console.error('Error loading bar images:', error);
        setBarData(prevState => ({ ...prevState, loadingImages: false, imageError: true }));
      }
    };

    const fetchFilteredIteraciones = async () => {
      if (!selectedBar?.iteraciones) return;
  
      try {
        const filteredIteraciones = filterIteraciones(selectedBar.iteraciones || []);
  
        setBarData(prevState => ({
          ...prevState,
          iteracionesFiltradas: filteredIteraciones,
        }));
      } catch (error) {
        console.error('Error filtering iteraciones:', error);
      }
    };

    const fetchRatingsData = async () => {
      try {
        const newRatingsUser = filterRatingsUser(ratings, selectedBar._id);
        const newRatingsUserBar = filterRatingsUserBar(ratings, selectedBar._id);
  
        setBarData(prevState => ({
          ...prevState,
          ratingsUser: newRatingsUser,
          ratingsUserBar: newRatingsUserBar,
        }));
      } catch (error) {
        console.error('Error filtering ratings:', error);
      }
    };

    if (selectedBar?._id) {
      fetchBarImages();
      fetchFilteredIteraciones();
      fetchRatingsData();
    }
  }, [selectedBar, ratings]);

  const handleBarAction = useCallback(async (action, setter) => {
    if (!authenticated) {
        setIsOpenSinSesion(true);
        return;
    }
    try {
      
      const response = await api.users[action]({ userId: user?._id, barId: selectedBar?._id });
      if (response.status === 200) {
        setter(prev => !prev);

        // Clonamos los arrays antes de actualizar
        let updatedFavoriteBars = [...user.favoriteBars];
        let updatedWishBars = [...user.wishBars];

        // Actualizamos el array correspondiente dependiendo de la acción
        if (action === 'addFavoriteBar') {
            updatedFavoriteBars = [...updatedFavoriteBars, selectedBar._id];
        } else if (action === 'removeFavoriteBar') {
            updatedFavoriteBars = updatedFavoriteBars.filter(barId => barId !== selectedBar._id);
        }

        const newUser = {
            ...user,
            favoriteBars: updatedFavoriteBars,
            wishBars: updatedWishBars,
        };

        // Imprime el nuevo objeto user para verificarlo
        console.log("Nuevo objeto user que se enviará a guardarUser:", newUser);

        // Guardamos el usuario actualizado en el estado global
        guardarUser(newUser);

      }
    } catch (error) {
        console.error(`Error ${action}:`, error);
    }
  }, [authenticated, user, selectedBar?._id]);

  const handleLikeClick = useCallback(() => handleBarAction(isLiked ? 'removeFavoriteBar' : 'addFavoriteBar', setIsLiked), [handleBarAction, isLiked]);
  useEffect(() => {
    if (authenticated && user) {
        setIsLiked(user.favoriteBars.includes(selectedBar._id));
    }
  }, [authenticated, user, selectedBar?._id]);

  const handleShareClick = () => {
    const shareUrl = window.location.href;
    if (navigator.share) {
      // Web Share API
      navigator.share({
        title: selectedBar.name,
        text: t(`compartir.texto`),
        url: shareUrl,
      })
      .then(() => setShared(true))
      .catch((error) => console.error('Error al compartir:', error));
    } else {
      // Compartir personalizado (desktop)
      
      navigator.clipboard.writeText(shareUrl).then(() => {
        alert(t(`compartir.alert`));
        setShared(true);
      });
    }
  };

  const handleVolver = () => {
    navigate('/map'); 
  }

  //esto para que sirve? 
  useEffect(() => {
    const handlePopState = (event) => {
        event.preventDefault();
        handleVolver();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleCloseSinSesion = () => {
    setIsOpenSinSesion(false);
  }

  const handleImageClick = (index) => {
    
    if (index !== 0) {
      setActiveImageIndex(index); // Configuramos la imagen activa
      setModalOpen(true); // Abrimos el modal
    }
  };

  const abrirNotificaciones = (temaPulsado) => {
    setTemaSeleccionado(temaPulsado);
    setModalOpenNotifications(true); 
  }

  if (!selectedBar) return null;

  return (
    <Container className="bar-detalles-container max-w-4xl mx-auto px-4 py-8">
      <div className="relative overflow-hidden rounded-lg shadow-lg mb-8 h-64">
        <button
          onClick={handleVolver}
          className="absolute left-4 top-4 bg-white/60 rounded-full p-1 z-50"
          aria-label="Volver a mapa"
        >
          <ChevronLeft className="h-6 w-6 text-gray-800" />
        </button>

        {/* Botón de "Compartir" y "Me Gusta" */}
        <button 
          onClick={handleShareClick}
          className="absolute top-4 right-16 bg-white/80 rounded-full p-2 shadow-md z-10" 
          aria-label="Share button"
          >
          <Share />
        </button>
        <button
          onClick={handleLikeClick}
          className="absolute top-4 right-4 bg-white/80 rounded-full p-2 shadow-md z-10"
          aria-label="Like button"
        >
          <FontAwesomeIcon icon={isLiked ? solidHeart : regularHeart} size="lg" className='icon-btn'/> 
        </button>

        {!barData.loadingImages && !barData.imageError && barData.barImages?.length > 0 ? (
          <>
            <Swiper
              modules={[Navigation, Pagination]}
              navigation
              pagination={{ clickable: true }}
              className="h-full w-full"
              loop
            >
              {barData.barImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="relative w-full h-full">
                    <img
                      src={image}
                      alt={`Bar image ${index}`}
                      className="object-cover w-full h-full z-10"
                      onClick={() => handleImageClick(index)} // Al hacer clic, se abre el modal para las imágenes distintas a 0
                    />
                    {index === 0 && (
                      <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-b from-black/50 to-black/20 p-4">
                        <h1 className="text-3xl font-bold text-white text-center mb-4 shadow-text">
                          {selectedBar.name}
                        </h1>
                        <div className="flex flex-wrap justify-center gap-2">
                          {selectedBar.types.map((tipo, idx) => (
                            <Chip key={idx} color="primary" variant="shadow" className="category-chip">
                              {t(`types.${tipo}`)}
                            </Chip>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : (

          <div className="relative w-full h-full bg-white shadow-lg">
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-b from-black/50 to-black/20 p-4">
              <h1 className="text-3xl font-bold text-gray-900 text-center mb-4 shadow-text">{selectedBar.name}</h1>
              <div className="flex flex-wrap justify-center gap-2">
                {selectedBar.types.map((tipo, idx) => (
                  <Chip key={idx} color="primary" variant="shadow" className="category-chip">
                    {t(`types.${tipo}`)}
                  </Chip>
                ))}
              </div>
            </div>
          </div>

        )}
      </div>

      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-4">{selectedBar.name}</h2>
        <BarStatusChip hoursArray={selectedBar.openingHours?.weekdayDescriptions} />
      </div>

      <div className="text-center mt-8">
        <Button color="primary" variant="shadow" onClick={() => abrirNotificaciones(null)} >
          {t('detallesBares.tuOpinion')}
        </Button>
      </div>
      
      {selectedBar && (
        barData.iteracionesFiltradas &&
        barData.ratingsUser &&
        barData.ratingsUserBar ? (
          <BarDetallesTabs
            iteracionesFiltradas={barData.iteracionesFiltradas}
            bar={selectedBar}
            iteraciones={selectedBar.iteraciones}
            ratingsUser={barData.ratingsUser}
            ratingsUserBar={barData.ratingsUserBar}
            abrirNotificaciones={abrirNotificaciones}
          />
        ) : (
          <p>Cargando datos...</p> // Placeholder mientras se cargan los datos
        )
      )}

      <div className="text-center mt-8">
        <Button color="primary" onClick={() => abrirNotificaciones(null)} size="lg">
          {t('detallesBares.opina')}
        </Button>
      </div>

      {isModalOpenNotifications && (
        <NotificationsPopUp 
          isOpen={isModalOpenNotifications} 
          onOpenChange={(isOpen) => setModalOpenNotifications(isOpen)} 
          temaSeleccionado={temaSeleccionado}
        />
      )}

      {isOpenSinSesion && <SinSesion onClose={handleCloseSinSesion} />}

       {/* Modal para mostrar las imágenes en grande */}
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} placement='center' className="flex items-center justify-center">
        <ModalContent>
          <div className="w-full max-w-4xl bg-gray-800 p-4 rounded-lg shadow-lg">
            <Swiper
              modules={[Zoom, Navigation, Pagination]}
              navigation={true}
              zoom={true}
              pagination={{ clickable: true }}
              initialSlide={activeImageIndex} // Empezamos en la imagen que se seleccionó
              loop
              lazy={true}
              style={{ height: '60vh', width: '100%' }}
            >
              {barData.barImages.map((image, index) => (
                <SwiperSlide key={index} className="flex items-center justify-center h-full">
                  <div className="relative flex items-center justify-center w-full h-[56vh]">
                    <img
                      src={image}
                      alt={`Bar image ${index}`}
                      className="object-contain w-full h-full overflow-hidden rounded-lg shadow-lg"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </ModalContent>
      </Modal>

    </Container>
  );
};

export default BarDetalles;
