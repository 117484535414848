import React, { useEffect, useState } from 'react';
import imgUbicacion from '../../assets/icons/marcador.png'; 
import imgHorario from '../../assets/icons/reloj.png'; 
import imgRating from '../../assets/icons/estrella.png'; 
import imgWeb from '../../assets/icons/web.png'; 
import imgAmigo from '../../assets/icons/amigo.png'; 
import imgTelefono from '../../assets/icons/telefono.png'; 
import IconTextSection from './IconTextSection';
import { Divider, Image } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import MapUtils from '../../utils/MapUtils';

const InformacionSection = ({ bar, iteraciones }) => {

    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Detectar si es un dispositivo móvil
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        setIsMobile(/android|iphone|ipad|ipod/i.test(userAgent));
    }, []);

    const handleShareClick = () => {
        const shareUrl = window.location.href;
        if (navigator.share) {
          // Web Share API
          navigator.share({
            title: bar.name,
            text: t(`compartir.texto`),
            url: shareUrl,
          })
          .then(() => setShared(true))
          .catch((error) => console.error('Error al compartir:', error));
        } else {
          // Compartir personalizado (desktop)
          
          navigator.clipboard.writeText(shareUrl).then(() => {
            alert(t(`compartir.alert`));
            setShared(true);
          });
        }
      };
    
    return (
        <>
            <IconTextSection
                icon={imgRating} 
                content={`${MapUtils.formato1Decimal(bar.rating)} (${iteraciones.numRatings} opiniones de usuarios)`} 
                translationKey="starsRating" 
            />
            <Divider className='bg-primary'/>
            <IconTextSection 
                icon={imgUbicacion} 
                content={bar.address_short} 
                translationKey="ubicacion"
            />
            <Divider className='bg-primary'/>
            <IconTextSection
                icon={imgHorario} 
                translationKey="horario"
                content={
                    <>
                        {bar.openingHours?.weekdayDescriptions?.map((horarioDia, index) => (
                            <div key={index} className="horario-dia">
                                <small>{horarioDia}</small>
                            </div>
                        ))}
                    </>
                } 
            />
            <Divider className='bg-primary'/>
            <div className="flex gap-3">
                <Image src={imgTelefono} alt="telefono" height={28} width={28} radius="sm" className="category-chip" />
                <div className="bar-detalles-types">
                    {bar.numberPhone ? (
                        isMobile ? (
                            // Si es un dispositivo móvil, usa tel: para llamadas
                            <a 
                                href={`tel:${bar.numberPhone}`} 
                                className="category-chip text-primary hover:underline"
                            >
                                {bar.numberPhone}
                            </a>
                        ) : (
                            // Si no es móvil, simplemente muestra el número
                            <p className="category-chip">{bar.numberPhone}</p>
                        )
                    ) : (
                        <p className="category-chip">{t(`notificaciones.subtemas.telefono.error`)}</p>
                    )}
                </div>
            </div>
            <Divider className='bg-primary'/>
            <div className="flex gap-3">
                <Image src={imgWeb} alt="web" height={28} width={28} radius="sm" className="category-chip" />
                <div className="bar-detalles-types">
                    {bar.websiteUri ? 
                        <a 
                            href={bar.websiteUri} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="category-chip text-primary hover:underline"
                        >
                            {bar.websiteUri}
                        </a>
                        : 
                        <p className='category-chip'>{t(`notificaciones.subtemas.web.error`)}</p>    
                    }
                </div>
            </div>
            <Divider className='bg-primary'/>
            <div className="flex gap-3">
                <Image src={imgAmigo} alt="amigo" height={28} width={28} radius="sm" className="category-chip" />
                <div className="bar-detalles-types">
                    <a 
                        onClick={handleShareClick}
                        className="category-chip text-primary hover:underline"
                    >
                        {t(`notificaciones.subtemas.amigo.texto`)}
                    </a> 
                </div>
            </div>
        </>
    );
}

export default InformacionSection;
