import './styles/main.css';
import './styles/custom.scss';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import AppRoutes from './Routes';
import CookieConsent from 'react-cookie-consent';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Analytics/> 
        <SpeedInsights />
      </header>
      <main>
        <AppRoutes/>
        <CookieConsent
          location="bottom"
          buttonText="Aceptar"
          cookieName="userConsent"
          style={{
            background: "#2B373B",
            color: "#fff",
            fontSize: "14px",
            padding: "10px",
          }}
          buttonStyle={{
            background: "#227c73",
            color: "#fff",
            fontSize: "13px",
            padding: "5px 20px",
            borderRadius: "3px",
          }}
          expires={365}
        >
          Usamos cookies para mejorar su experiencia. Al continuar navegando, acepta el uso de cookies según nuestra{' '}
          <a href="/privacy-policy" style={{ color: '#fff' }}>
            política de privacidad
          </a>.
        </CookieConsent>
      </main>
    </div>
  );
}

export default App;
