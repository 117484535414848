import { Button, Popover, PopoverContent, PopoverTrigger, Tooltip } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import './BotonNotificarBar.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots as regularfaCommentDots } from '@fortawesome/free-regular-svg-icons';
import { faCommentDots as solidfaCommentDots} from '@fortawesome/free-solid-svg-icons';
import NotificationsPopUp from '../NotificationsPopUp/NotificationsPopUp';
import { useTranslation } from 'react-i18next';

    
const BotonNotificarBar = ({ selectedBar }) => {
    const { t } = useTranslation();
    const [isModalOpenNotifications, setModalOpenNotifications] = useState(false);
    const [numTooltip, setNumTooltip] = useState(Math.floor(Math.random() * 10) + 1); // Usar useState para numTooltip


    const handleNotificationClick = () => {
        setModalOpenNotifications(true);
    };

    useEffect(() => {
        // Actualizar numTooltip cuando selectedBar cambie
        setNumTooltip(Math.floor(Math.random() * 10) + 1);
    }, [selectedBar]);

    return (
        <>
        <div className="floating-button-container">
            <Tooltip showArrow={true} placement='top-end' isOpen={true} onClick={handleNotificationClick}  
                style={{ zIndex: 10 }}
                content={
                    <div className="px-1 py-1">
                        <div className="text-xs font-bold">{t(`tooltip.${numTooltip}`)}</div>
                    </div>
                    }
                    classNames={{
                        base: [
                            // Color de la flecha
                            "before:bg-primary-dark dark:before:bg-primary-dark",
                            "mr-4"
                        ],
                        content: [
                            // Estilos del contenido del tooltip
                            "shadow-xl",
                            "text-primary-dark bg-secondary border-2 border-primary-dark",
                        ],
                    }}
                >
                    <Button 
                        isIconOnly  
                        onClick={handleNotificationClick} 
                        className="custom-button"
                        radius='full'
                        variant="shadow"
                        aria-label="Notificaciones"
                    >
                        <FontAwesomeIcon icon={solidfaCommentDots} className='icon-btn-notificacion' />
                    </Button>
            </Tooltip>
        </div>

        {isModalOpenNotifications && (
            <NotificationsPopUp isOpen={isModalOpenNotifications} onOpenChange={setModalOpenNotifications} />
        )}
        </>
    );
};

export default BotonNotificarBar;