import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea, useDisclosure } from '@nextui-org/react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { obtenerListadoTemasPrincipales } from '../../utils/incidenciasUtils';
import IconosTemas from './IconosTemas';
import SubtemasPopUp from './SubtemasPopUp';
import RatingStar from '../RatingStar/RatingStar';
import { useUser } from '../../contexts/UserContext';

const NotificationsPopUp = ({ isOpen, onOpenChange, temaSeleccionado }) => {
    const { selectedBar } = useUser();
    const [isPrincipalOpen, setIsPrincipalOpen] = useState(null);  // Controla el modal principal
    const [selectedTema, setSelectedTema] = useState(null); // Controla el submodal
    const { t } = useTranslation();

    // Sincroniza el estado local con `isOpen`
    /*useEffect(() => {
      setIsPrincipalOpen(isOpen);
    }, [isOpen]);*/

    useEffect(() => {
      if (isOpen && temaSeleccionado) {
        
        const temaEncontrado = listTemasPrincipales.find(
          (tema) => tema.id === temaSeleccionado
        );
        setIsPrincipalOpen(false);
        setSelectedTema(temaEncontrado);

      } else {
          // Si no hay `temaSeleccionado`, abre Principal
          setIsPrincipalOpen(isOpen);
          setSelectedTema(null);
      }
  }, [isOpen, temaSeleccionado]);
    
    // Cierra el modal principal
    const closeModal = () => {
      setIsPrincipalOpen(false);
      onOpenChange(false);
      setSelectedTema(null);
    };

    // Manejador de eventos para el clic en un tema principal
    const handleTemaPrincipalClick = (temaPrincipal) => {
		  setSelectedTema(temaPrincipal);
      setIsPrincipalOpen(true);
    };

    // Manejador de eventos para cerrar el popup de subtemas
    const handleCloseSubtemasPopUp = () => {
		  setSelectedTema(null);
      setIsPrincipalOpen(true);
    };

    const listTemasPrincipales = obtenerListadoTemasPrincipales();

    return (
        <>
        <Modal isOpen={isPrincipalOpen} onOpenChange={closeModal}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 pb-0 border-b border-gray-200">
                  <h4>{t('notificaciones.titulo')}</h4>
                </ModalHeader>
                <ModalBody>
                  <div className="rating-star-container">
                    <p><RatingStar selectedBar={selectedBar} /></p>
                  </div>

                  <div className="temas-container">
                    {listTemasPrincipales.map((temaPrincipal, index) => (
                      <IconosTemas 
                        tema={temaPrincipal} 
                        key={index}
                        onTemaClick={handleTemaPrincipalClick}
                      />
                    ))}
                  </div>
                  
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>

        {/* Mostrar el popup de subtemas si se ha seleccionado un tema */}
        {selectedTema && (
          <SubtemasPopUp
            isOpen={true}
            closeSubtemas={handleCloseSubtemasPopUp}
            temaPrincipal={selectedTema}
            selectedBar={selectedBar}
          />
        )}
      </>
    )
}

export default NotificationsPopUp;