import { openDB } from 'idb';

export const initDB = async (dbName, storeName, version = 1) => {
  return openDB(dbName, version, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'id' }); // 'id' es clave única
      }
    },
  });
};
