import React from 'react';
import './MenuLateral.styles.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ImageComentario from '../../assets/icons/comentario.png';
import ImageConfiguracion from '../../assets/icons/configuraciones.png';
import ImageLike from '../../assets/icons/like.png';
import ImageCerrarSesion from '../../assets/icons/cerrar-sesion.png';
import ImageGuardados from '../../assets/icons/save.png';
import ImagePerfil from '../../assets/icons/usuario.png';
import ImageSinPerfil from '../../assets/icons/foto-perfil.png';
import ImageGyp from '../../assets/icons/pulgar-arriba-negro.png';
import ImageInstagram from '../../assets/icons/icons-instagram.png';
import ImageFacebook from '../../assets/icons/icons-facebook.svg';
import ImageMail from '../../assets/icons/icons-mail.png';
import { Listbox, ListboxItem, User } from '@nextui-org/react';
import { OffcanvasBody, OffcanvasHeader, OffcanvasTitle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ListboxWrapper } from './ListboxWrapper';
import api from '../../services/ApiService';
import { useUser } from '../../contexts/UserContext';

const MenuLateral = ({
    handleProfileClick,
    handleMenuOptionClick,
    handleGyp,
    handleConfiguracion,
    handleLogout,
    handleCloseMenuLateral
}) => {
    const { user, authenticated, obtenerImagenPerfil } = useUser();
    const { t } = useTranslation();

    const handleMenuSelect = (option) => {
        switch (option) {
            case 'guardados':
                handleMenuOptionClick(api.users.getWishBars, false);
                break;
            case 'meGusta':
                handleMenuOptionClick(api.users.getFavoriteBars, true);
                break;
            case 'opiniones':
                handleComentarios();
                break;
            case 'gyp':
                handleGyp();
                break;
            case 'configuracion':
                handleConfiguracion();
                break;
            case 'cerrarSesion':
                handleLogout();
                break;
            default:
                break;
        }
    };
    
    const handleComentarios = async () => {
        if (user){
            alert("Mostrar Comentarios");
        }else{
            openModalSinSesion();
        }
    }

    const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0  w-10";
    
    return (
        <>
        <Offcanvas 
            show={true} 
            onHide={handleCloseMenuLateral}   
            className="menu-lateral px-4 py-2" 
        >
            <OffcanvasHeader className='d-flex justify-content-between align-items-center' closeButton>
                { user ? 
                    <User
                        name={user?.profile?.given_name}
                        description={user?.email}
                        avatarProps={{
                            isBordered: true,
                            color:"primary" ,
                            src:obtenerImagenPerfil(user),
                            alt:"Imagen de Perfil", 
                            size: "lg",
                            onClick: handleProfileClick,
                        }}
                    />    
                    :
                    <>
                    <User
                        src={ImageSinPerfil}
                        avatarProps={{
                            isBordered: true,
                            alt:"Imagen de Perfil", 
                            size: "lg"
                        }}
                    />
                    <OffcanvasTitle>{t('menu')}</OffcanvasTitle>
                    </>
                }
                
            </OffcanvasHeader>
            <hr className="my-4" />
            <OffcanvasBody className='d-flex flex-column h-100'>
                <ListboxWrapper>
                    <Listbox 
                        aria-label="User Menu"
                        variant="faded">
                        <ListboxItem
                            key="perfil"
                            onClick= {handleProfileClick}
                            showDivider
                            startContent={<img src={ImagePerfil} alt="perfil" className={iconClasses} />}>
                        {t('menuLateral.infoPersonal')}
                        </ListboxItem>
                        <ListboxItem
                            key="gyp"
                            onClick={() => handleMenuSelect('gyp')}
                            startContent={<img src={ImageGyp} alt="gustosYPreferencias" className={iconClasses} />}>
                        {t('menuLateral.gyp')}
                        </ListboxItem>
                        {/* <ListboxItem
                            key="guardados"
                            onClick={() => handleMenuSelect('guardados')}
                            startContent={<img src={ImageGuardados} alt="guardados" className={iconClasses} />}>
                        {t('menuLateral.guardados')}
                        </ListboxItem> */}
                        <ListboxItem
                            key="meGusta"
                            onClick={() => handleMenuSelect('meGusta')}
                            startContent={<img src={ImageLike} alt="meGusta" className={iconClasses} />}>
                        {t('menuLateral.meGusta')}
                        </ListboxItem>
                        {/* <ListboxItem
                            key="opiniones"
                            onClick={() => handleMenuSelect('opiniones')}
                            startContent={<img src={ImageComentario} alt="opiniones" className={iconClasses} />}>
                        {t('menuLateral.opiniones')}
                        </ListboxItem> */}
                        <ListboxItem
                            key="configuracion"
                            onClick={() => handleMenuSelect('configuracion')}
                            showDivider
                            startContent={<img src={ImageConfiguracion} alt="configuracion" className={iconClasses} />}>
                        {t('menuLateral.configuracion')}
                        </ListboxItem>
                        { authenticated && (
                            <ListboxItem
                                key="cerrarSesion"
                                className="text-danger"
                                onClick={() => handleMenuSelect('cerrarSesion')}
                                color="danger"
                                startContent={<img src={ImageCerrarSesion} alt="cerrarSesion" className={iconClasses} />}>
                            {t('menuLateral.cerrarSesion')}
                            </ListboxItem>
                         )}
                    </Listbox>
                </ListboxWrapper>
                <div className="mt-auto border-top">
                    <ul className="list-unstyled">
                         
                        <li className="pt-3">
                            <a href="https://www.instagram.com/esmibar_es/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center text-decoration-none text-muted">
                                <img src={ImageInstagram} alt="Instagram" width="24" height="24" />
                                <span className="ms-2">@esmibar_es</span>
                            </a>
                        </li>
                        {/* <li className="pt-3">
                            <a href="https://www.facebook.com/esmibar/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center text-decoration-none text-muted">
                                <img src={ImageFacebook} alt="Facebook" width="24" height="24" />
                                <span className="ms-2">@esmibar</span>
                            </a>
                        </li> */}
                        <li className="py-3">
                            <a href="mailto:esmibar.adm@gmail.com" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center text-decoration-none text-muted">
                                <img src={ImageMail} alt="mail" width="24" height="24" />
                                <span className="ms-2">esmibar.adm@gmail.com</span>
                            </a>
                        </li>

                        <li className="py-2 border-top">
                            <a 
                                href="/privacy-policy" 
                                className="text-decoration-none hover:text-primary">
                                {t('menuLateral.politicaPrivacidad')}
                            </a>
                        </li>
                        <li className="pb-2">
                            <a 
                                href="/terms-of-service" 
                                className="text-decoration-none hover:text-primary">
                                {t('menuLateral.terminosCondiciones')}
                            </a>
                        </li>
                        <li className="pb-3">
                            <a 
                                href="/cookie-policy" 
                                className="text-decoration-none hover:text-primary">
                                {t('menuLateral.cookies')}
                            </a>
                        </li>
                        
                        <li className="pt-3 border-top">
                            <span>{process.env.REACT_APP_NAME} - {process.env.REACT_APP_VERSION}</span>
                        </li>
                    </ul>
                </div>

            </OffcanvasBody>
        </Offcanvas>
        </>
    );
};

export default MenuLateral;