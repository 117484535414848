import { Chip, Image } from '@nextui-org/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const IconTextComentarioSection = ({ icon, content, abrirNotificaciones }) => {
    const { t } = useTranslation();
    return (
        <div className="flex gap-3">
            <Image src={icon} height={28} width={28} radius="sm" className="category-chip" />
            <div className="bar-detalles-types">
                <h6 className="category-chip">{t(`notificaciones.subtemas.comentarios.titulo`)}</h6>
                { content ? (
                    <p className="category-chip">{content}</p>
                ) : (
                    <p onClick={() => abrirNotificaciones("comentarios")} className="category-chip">{t('notificaciones.subtemas.miOpinionNoResults')}</p>
                )}
            </div>
        </div>
    )

};

export default IconTextComentarioSection;
