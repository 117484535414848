import React from 'react';
import './SplashScreen.style.scss'
import logo from '../../assets/icons/marca/LogoCompletoVerde.png';
import lupa from '../../assets/icons/buscar1.png';
import useUserLocation from '../../hooks/useUserLocation';
import CiudadSelector from '../CiudadSelector/CiudadSelector';
import { useLoading } from '../../hooks/useLoading';

const SplashScreen = () => {
  const { modalVisible, handleSelectCity, handleReubicar } = useUserLocation();
  const {isLoading} = useLoading();

  return (
    <>
      <div className="splash-container">
        <div className="splash-content">
          <img src={logo} alt="Logo EsMiBar" className="splash-logo" />
          <div className="loading-bar">
            <div className="loading-bar-progress"></div>
          </div>
          <div className="search-animation">
            <img src={lupa} alt="Buscando bares" className="search-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SplashScreen;
