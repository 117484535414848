import { FILTROS } from "../constants";

function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

function getAddres(fullAddress) {
    // Dividir el string en cada coma
    const addressParts = fullAddress.split(',');
    // Tomar las dos primeras divisiones
    return addressParts.slice(0, 2).join(',').trim();
}

function formato1Decimal(num) {
  const roundedNum = Math.round(num * 10) / 10;
  return roundedNum.toString();
};

function asignarFiltros(filtros){

    let filtrosSalida = [];
    
    //types de google mas tipos mios.
    filtros.forEach(filtro => {
        switch (filtro) {
            case "bar":
                filtrosSalida.push("establishment","bar","cerveceria","tapas","azotea");
                break;
            case "cafeteria":
                filtrosSalida.push("cafe","coffee_shop","bakery");
                break;
            case FILTROS.vinos:
                filtrosSalida.push("barDeVinos","barDeCocteles");    
                break;
            default:
                break;
        }
    });

    return filtrosSalida;
}

export default {
    capitalizeFirstLetter,
    getAddres,
    formato1Decimal,
    asignarFiltros
}