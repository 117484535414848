// LoadingContext.js
import React, { createContext, useState } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    const startLoading = () => {
        console.log("startLoading");
        setIsLoading(true)
    };

    const stopLoading = () => {
        console.log("stopLoading");
        setIsLoading(false);
    }

    return (
        <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingContext;
