function getUser() {
    const userJSON = localStorage.getItem('user');
    // Convierte la cadena JSON de vuelta a un objeto
    if (userJSON !== null && userJSON !== undefined){
        try {
            return JSON.parse(userJSON);
        } catch (error) {
            console.error("Error al analizar JSON:", error);
            return null;
        }
    }  
    return null;
}

function setUser(user) {
    const userJSON = JSON.stringify(user);
    localStorage.setItem('user', userJSON);
}

function isAuth() {
    const isAuth = localStorage.getItem('authToken');
    return !!isAuth; 
}

function getAuth() {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('authToken');
    return {user, token}; 
}

function setAuth(user,token) {
    // Convertir el objeto plano a una cadena JSON
    const userJSON = JSON.stringify(user);
    localStorage.setItem('user', userJSON);
    localStorage.setItem('authToken', token);
}

function logoutAuth() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
}

export default {
    getUser,
    setUser,
    isAuth,
    getAuth,
    setAuth,
    logoutAuth,
}