// contexts/TemasNotificacionesContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import apiTags from '../services/ApiTagService';
import { useTranslation } from 'react-i18next';

const TemasNotificacionesContext = createContext();

export const TemasNotificacionesProvider = ({ children }) => {
    const [allTemasPrincipales, setAllTemasPrincipales] = useState([]);
    const [temasPrincipalesVisibles, setTemasPrincipalesVisibles] = useState([]);
    const [listTemasPrincipalesVisibles, setListTemasPrincipalesVisibles] = useState([]);
    const [temasConSubtemasVisibles, setTemasConSubtemasVisibles] = useState([]);
    const [temasConTodosSubtemas, setTemasConTodosSubtemas] = useState([]);
    const [temasFiltroRapido, setTemasFiltroRapido] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchTemas = async () => {
            try {

                const tagsFiltroRapidoCompleto = await apiTags.getFiltrosRapidos();
                const temasFiltroRapido = getTemasFiltroRapido(tagsFiltroRapidoCompleto);
                setTemasFiltroRapido(temasFiltroRapido);
               

                const temasVisibles = await apiTags.getListTemasPrincipalesVisibles();
                setTemasPrincipalesVisibles(temasVisibles);
                
                const temasConSubtemasVisibles = await getTemasConSubtemasVisibles(temasVisibles);
                setTemasConSubtemasVisibles(temasConSubtemasVisibles);

                const temasConTodosSubtemas = await getTemasConAllSubtemas(temasVisibles);
                setTemasConTodosSubtemas(temasConTodosSubtemas);

            } catch (error) {
                console.error("Error fetching temas principales:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchTemas();
    }, []);
    
    const getTemasFiltroRapido = (tags) => {
        return tags.map((tag) => {
            return {
                id: tag.style,
                title: t(`notificaciones.temaPrincipal.${tag.style}`),
                img: getDynamicImagePath(tag.style,"temasPrincipales"),
                subtemas: [{
                    id: tag.name,
                    title: t(`tags.${tag.style}.${tag.name}`),
                    img: getDynamicImagePath(tag.name,"filtrosRapidos")
                }]
            };
        })
    };

    const getTemasConSubtemasVisibles = async (temasVisibles) => {
        
        const temasOrdenados = ordenarTemasVisibles(temasVisibles);

        return await Promise.all(temasOrdenados.map(async (tema) => {
            const subtemasVisibles = await apiTags.getListSubtemasVisiblesByTema(tema);
            return {
                id: tema,
                title: t(`notificaciones.temaPrincipal.${tema}`),
                img: getDynamicImagePath(tema,"temasPrincipales"),
                subtemas: subtemasVisibles.map(subtema => ({
                    id: subtema.name,
                    title: t(`tags.${tema}.${subtema.name}`),
                    img: getDynamicImagePath(subtema.name,"subtemas")
                }))
            };
        }));
    };
    
    const getTemasConAllSubtemas = async (temasVisibles) => {

        // Filtrar los temas que no tienen el estilo 'comentarios'
        const temasFiltrados = temasVisibles.filter(tema => tema !== 'comentarios');

        const temasOrdenados = ordenarTemasVisibles(temasFiltrados);

        return await Promise.all(temasOrdenados.map(async (tema) => {
            const todosSubtemas = await apiTags.getAllListSubtemasByTema(tema);

            // Ordenar subtemas (alfabéticamente por 'title')
            const subtemasOrdenados = todosSubtemas
                .map(subtema => ({
                    id: subtema.name,
                    title: t(`tags.${tema}.${subtema.name}`),
                    img: getDynamicImagePath(subtema.name, "subtemas"),
                }))
                .sort((a, b) => a.title.localeCompare(b.title)); // Ordenar alfabéticamente

            return {
                id: tema,
                title: t(`notificaciones.temaPrincipal.${tema}`),
                img: getDynamicImagePath(tema,"temasPrincipales"),
                subtemas: subtemasOrdenados,
            };
        }));
    };

    const getDynamicImagePath = (temaId,path) => {
        try {
            // Aquí se construye la ruta de la imagen dinámicamente
            return require(`../assets/icons/${path}/${temaId}.png`);
        } catch (error) {
            console.warn(`No se encontró la imagen para el tema: ${temaId}`);
            return null; // Si no se encuentra la imagen, retorna null
        }
    };

    const ordenarTemasVisibles = (temasVisibles) => {
        
        const ordenPredefinido = ["tipoLocal", "caracteristicas", "precio", "gente", "ambiente", "comentarios"];
        
        const mapaDeOrden = {};
        ordenPredefinido.forEach((tema, index) => {
            mapaDeOrden[tema] = index;
        });

        // Asignar un valor alto a 'comentarios' para que siempre sea el último
        const comentariosIndex = ordenPredefinido.length;


        // Ordenar los temas según el orden predefinido, poniendo los que no están en orden al final
        return temasVisibles.sort((a, b) => {
            const indexA = mapaDeOrden[a] !== undefined ? mapaDeOrden[a] : comentariosIndex - 1; // Si no está en orden, va al final
            const indexB = mapaDeOrden[b] !== undefined ? mapaDeOrden[b] : comentariosIndex - 1; // Si no está en orden, va al final
            return indexA - indexB;
        });

    };

    return (
        <TemasNotificacionesContext.Provider value={{ 
            allTemasPrincipales, 
            temasConSubtemasVisibles, 
            temasConTodosSubtemas,
            temasFiltroRapido,
            loading 
            
            }}>

            {children}
        </TemasNotificacionesContext.Provider>
    );
};

export const useTemasNotificaciones = () => useContext(TemasNotificacionesContext);
