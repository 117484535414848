// src/Routes.js
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolity';
import TermsOfService from './components/TermsOfService';
import CookiePolicy from './components/CookiePolicy';
import BarInformacion from './components/BarInformacion';
import { UserProvider } from './contexts/UserContext';
import { TemasNotificacionesProvider } from './contexts/TemasNotificacionesContext';
import { LoadingProvider } from './contexts/LoadingContext';
import SplashScreen from './components/SplashScreen/SplashScreen';
import { MapProvider } from './contexts/MapContext';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';

const Map = lazy(() => import('./components/Map/Map'));

function AppRoutes() {

  useGoogleAnalytics();

  return (
    <UserProvider>
    <MapProvider>
    <TemasNotificacionesProvider>
    <LoadingProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/map" element={<Suspense fallback={<SplashScreen/>}><Map /></Suspense>} />
        <Route path="/bar/:id" element={<BarInformacion />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
    </LoadingProvider>
    </TemasNotificacionesProvider>
    </MapProvider> 
    </UserProvider> 
  );
}

export default AppRoutes;
